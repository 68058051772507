/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {StateCta, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "are-ear-infections-contagious-everything-you-need-to-know",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-ear-infections-contagious-everything-you-need-to-know",
    "aria-label": "are ear infections contagious everything you need to know permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are Ear Infections Contagious? Everything You Need to Know"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-impaired-phone-hero.png",
    alt: "Leaf falling from the sky",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear infections, known medically as otitis media, are a common ailment affecting both children and adults. Understanding whether ear infections are contagious is crucial for managing symptoms and preventing their spread. Here’s a comprehensive guide to help you navigate this topic:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-ear-infections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-ear-infections",
    "aria-label": "what are ear infections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are Ear Infections?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear infections occur when bacteria or viruses cause inflammation in the middle ear. This can result in pain, fluid buildup, and sometimes hearing difficulties. While anyone can get an ear infection, they are more prevalent in children due to their smaller Eustachian tubes, which can trap bacteria and viruses more easily."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signs-and-symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signs-and-symptoms",
    "aria-label": "signs and symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signs and Symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signs of an ear infection may include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear pain or pulling at the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fluid drainage from the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty sleeping"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fever"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Irritability in infants and children"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-ear-infections-contagious",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-ear-infections-contagious",
    "aria-label": "are ear infections contagious permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are Ear Infections Contagious?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Yes, ear infections can be contagious, especially if caused by a viral or bacterial infection. The spread often occurs through respiratory droplets from coughing or sneezing, which can transmit the infection to others."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "remedies-and-treatments",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#remedies-and-treatments",
    "aria-label": "remedies and treatments permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Remedies and Treatments"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Treatment options vary depending on the type and severity of the infection:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Antibiotics"), ": Prescribed for bacterial infections."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Pain relievers"), ": Over-the-counter medications can help manage pain and fever."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ear drops"), ": Can provide relief from discomfort."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Warm compress"), ": Applied to the ear may alleviate pain."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "preventing-ear-infections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#preventing-ear-infections",
    "aria-label": "preventing ear infections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Preventing Ear Infections"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Reduce the risk of ear infections with these preventive measures:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Vaccination"), ": Stay up-to-date on vaccinations to prevent illnesses that can lead to ear infections."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Avoid secondhand smoke"), ": Exposure to smoke increases infection risk."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Hand hygiene"), ": Regular handwashing helps prevent the spread of germs."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-and-ear-infections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-and-ear-infections",
    "aria-label": "hearing loss and ear infections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Loss and Ear Infections"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Untreated or recurrent ear infections can lead to temporary or even permanent hearing loss. Fluid buildup in the middle ear can interfere with sound transmission, impacting hearing ability, especially in children whose speech and language development may be affected."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-can-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-can-help",
    "aria-label": "how hearcom can help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com Can Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Understanding the contagious nature of ear infections and taking proactive steps to manage and prevent them is essential for your health and well-being. Whether you’re seeking information or ready to improve your hearing, hear.com is here to make the process easy and straightforward."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you or a loved one experience hearing difficulties due to ear infections or any other cause, hear.com offers comprehensive solutions tailored to your needs. With a commitment to accessibility and quality, hear.com provides hearing aids to fit any budget. Our network includes the top 2% of audiologists in the United States, ensuring you receive expert care and personalized support."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "our-process-is-simple",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-process-is-simple",
    "aria-label": "our process is simple permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our process is simple:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fill out the questionnaire on our website."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Receive a call from a hearing expert who will guide you through your options."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Get your hearing aids delivered to your home in 48 hours or less."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Visit an audiologist near your location for a professional fitting."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Try out your hearing aids for 45 days to ensure they meet your needs."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Contact us today to schedule a consultation and take the first step towards better hearing. For more information, visit hear.com and discover how we can help you hear better, no matter your budget."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "SIGN UP TO TRY HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
